import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
    align-content: start;
    grid-column-gap: 12px;
    column-gap: 12px;
  }

  @media (min-width: 1440px) {
    -ms-grid-columns: 218px 1fr 218px 218px 218px 218px;
    grid-template-columns: 218px auto repeat(4, 218px);
  }

  &.mobile-grid {
    @media (max-width: 768px) {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: repeat(2, 1fr);
      -ms-grid-rows: 80px 20px;
      grid-template-rows: 80px 20px;
      grid-column-gap: 1.2rem;
      column-gap: 1.2rem;
      grid-row-gap: 2rem;
      row-gap: 2rem;

      & > div {
        &:first-child {
          -ms-grid-row: 2;
          grid-row: 2;
          -ms-grid-column-span: 2;
          grid-column: span 2;

          br {
            display: none;
          }
        }

        &:nth-child(2) {
          -ms-grid-row: 1;
          grid-row: 1;
          -ms-grid-column: 1;
          grid-column: 1;
        }

        &:nth-child(3) {
          -ms-grid-row: 1;
          grid-row: 1;
          -ms-grid-column: 2;
          grid-column: 2;
        }
      }
    }
  }
`

const Grid = ({ children, className }) => {
  return (
    <Container className={className}>
      {children}
    </Container>
  )
}

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Grid
