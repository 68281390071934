// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-clients-js": () => import("./../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-our-team-js": () => import("./../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-portfolio-of-services-js": () => import("./../src/pages/portfolio-of-services.js" /* webpackChunkName: "component---src-pages-portfolio-of-services-js" */),
  "component---src-templates-profile-js": () => import("./../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */)
}

