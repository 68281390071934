import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  -ms-grid-column: ${props => props.start};
  -ms-grid-column-span: ${props => props.end - props.start};
  grid-column: ${props => props.start} / ${props => props.end};
  justify-self: start;
  position: relative;
  padding-left: ${props => (props.insetContent ? props.insetContent : "")};

  @media (max-width: 1439px) {
    -ms-grid-column: ${props => props.tabletStart ? props.tabletStart : props.start };
    -ms-grid-column-span: ${props => props.tabletEnd ? props.tabletEnd - props.start : props.end - props.start};
    grid-column: ${props => props.tabletStart ? props.tabletStart : props.start } / ${props => props.tabletEnd ? props.tabletEnd : props.end};
    padding-left: 0;
  }
`

const GridItem = props => {
  return (
    <Container start={props.start} end={props.end} tabletStart={props.tabletStart} tabletEnd={props.tabletEnd} insetContent={props.insetContent}>
      {props.children}
    </Container>
  )
}

GridItem.propTypes = {
  children: PropTypes.node.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  insetContent: PropTypes.string,
}

export default GridItem
