import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import SVG from "../../static/img/logo.inline.svg"

const LogoStyles = styled.h1`
  text-indent: -9999px;
  margin: 0;
  font-size: 0;
  position: fixed;
  top: 36px;
  left: 36px;
  z-index: 9999;

  @media (max-width: 768px) {
    top: 20px;
    left: 20px;
  }

  a {
    display: block;
  }

  svg {
    width: 100%;
    max-width: 183px;
    height: auto;
    display: block;

    @media (max-width: 1439px) {
      max-width: 135px;
    }

    @media (max-width: 768px) {
      max-width: 100px;
    }
  }
`

const Logo = () => (
  <StaticQuery 
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <LogoStyles>
        <Link to={`/`} state={{ navIsOpen: false }}>
          {data.site.siteMetadata.title}
          <SVG />
        </Link>
      </LogoStyles>
    )}
  />  
)

export default Logo
