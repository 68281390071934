/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { StaticQuery, graphql } from "gatsby"

// Components
import Logo from "./logo"
import Grid from "./grid"
import GridItem from "./grid-item"
import Nav from './nav'

// Styles
import GlobalStyles from './styles/globalStyles'
import styled from 'styled-components'

// Styled Components
const Container = styled.div`
  padding: 1.2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  display: grid;
  grid-template-rows: 137px 1fr 195px auto;
  grid-template-areas: 
    "header"
    "main"
    "margin"
    "footer";
  
  @media (max-width: 768px) {
    grid-template-rows: 137px 1fr 100px 85px;
  }

  @media (min-width: 768px) {
    padding: 2rem 2rem 0;
  }
`

const BackgroundImage = styled(Img)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  will-change: opacity;
  transition: opacity 0.6s ease;
`
const BackgroundGradientOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all .6s ease;
  background: linear-gradient(to top, transparent, transparent, transparent, rgba(0,0,0,0.5)),
              linear-gradient(to right, transparent, rgba(0,0,46,0.2)),
              linear-gradient(to bottom, transparent, transparent, transparent, rgba(0,0,0,0.6));
  background-color: ${({ path }) => (path.includes('our-team') ? 'rgba(0,0,0,0.6)' : '')};
`
const Header = styled(Grid)`
  grid-area: header;
  position: fixed;
  z-index: 1;
  max-width: calc(1920px - 4rem);
  width: calc(100% - 4rem);
  top: 2rem;
  left: 2rem;
`
const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  z-index: 1;
  height: 100px;
  overflow: hidden;
  mask-image: linear-gradient(0deg, transparent 0%, #000 10px);
  pointer-events: none;

  @media (max-width: 768px) {
    height: 130px;
  }
`

const Main = styled(Grid)`
  grid-area: main;
`
const Footer = styled.div`
  grid-area: footer;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  & > div {
    margin-bottom: 2rem;
  }
`
const Margin = styled.div`
  grid-area: margin;
`

const Hamburger = styled.div`
  position: fixed;
  cursor: pointer;
  box-sizing: content-box;
  right: 0;
  top: 0;
  width: 30px;
  height: 15px;
  padding: 30px 20px;
  z-index: 9999;

  @media (min-width: 768px) {
    display: none;
  }

  div {
    position: relative;
    height: 100%;
    width: 100%;
  }

  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    transition: all 0.6s cubic-bezier(.39,.01,.32,.93);
    background-color: white;
    transform-origin: center;

    &:first-child {
      top: 0%;
    }

    &:last-child {
      top: 100%;
    }
  }

  &.is-active {
    span {
      top: 50%;

      &:first-child {
        transform: rotate(45deg);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
`

const BackgroundImageComponent = ({ path }) => (
  <StaticQuery 
    query={graphql`
      query bgImage {
        largeScreens: file(name: {eq: "background"}) {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        mobileDevices: file(name: {eq: "mobile-devices-background"}) {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      // Array of image data and `media` keys.
      // For displaying different images dependant on the screen size
      const backgroundImages = [
        data.mobileDevices.childImageSharp.fluid,
        {
          ...data.largeScreens.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]

      return (
        <BackgroundImage
          path={path}
          fluid={backgroundImages}
          alt="A cityscape view of Manhattan"
          fadeIn={true}
          fadeInDuration={200}
          style={{ position: `fixed` }}
        />
      )
    }}
  />
)

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navIsOpen: false
    }
  }

  toggleMobileNav = () => {
    this.setState(state => ({navIsOpen: !state.navIsOpen}))
  }

  render() {
    const { children } = this.props
    const { navIsOpen } = this.state
    let path = ""

    if(typeof window !== `undefined`) {
      path = this.props.path
    }

    return (
      <React.Fragment>
        <GlobalStyles />
        <Container id="site-container">
          <BackgroundImageComponent />
          <BackgroundGradientOverlay path={path} />
          <Mask>
            <BackgroundImageComponent />
            <BackgroundGradientOverlay path={path} />
          </Mask>
          
          <Header>
            <GridItem start="1" end="2">
              <Logo />
            </GridItem>
            <GridItem start="3" end="7">
              <Nav isOpen={navIsOpen} toggleMobileNav={this.toggleMobileNav} />
              <Hamburger 
                onClick={() => this.toggleMobileNav()}
                className={navIsOpen ? `is-active` : ``}
              >
                <div>
                  <span></span>
                  <span></span>
                </div>
              </Hamburger>
            </GridItem>
          </Header>

          <Main>
            {children}
          </Main>

          {/* CSS Grid Row — Margin to separate main from footer */}
          <Margin />

          <Footer>
            <Grid className="mobile-grid">
              <GridItem start="1" end="3">
                <p>For new client inquiries, contact us <br/>at <a href="mailto:inquire@weitzmanusa.com">inquire@weitzmanusa.com</a></p>
                <p className="pos-bottom">Full website in development</p>
              </GridItem>
              <GridItem start="3" end="5">
                <h4>New York Office</h4>
                <p>355 Lexington Ave., 5th Fl. <br/>New York, New York 10017 <br/>Tel.  212.949.4000</p>
              </GridItem>
              <GridItem start="5" end="7">
                <h4>Chicago Office</h4>
                <p>737 N. Michigan Ave., Ste. 2060 <br/>Chicago, Illinois 60611 <br/>Tel. 312.337.5785</p>
              </GridItem>
            </Grid>
          </Footer>
        </Container>
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
