import { createGlobalStyle } from "styled-components"
import fontLightWoff from "../../../static/fonts/light.woff"
import fontLightWoff2 from "../../../static/fonts/light.woff2"
import fontMediumWoff from "../../../static/fonts/medium.woff"
import fontMediumWoff2 from "../../../static/fonts/medium.woff2"
import fontSemiBoldWoff from "../../../static/fonts/semiBold.woff"
import fontSemiBoldWoff2 from "../../../static/fonts/semiBold.woff2"

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'GillSansNova-Light';
    src: url(${fontLightWoff2}) format('woff2'), url(${fontLightWoff}) format('woff');
  }
  @font-face {
    font-family: 'GillSansNova-SemiBold';
    src: url(${fontSemiBoldWoff2}) format('woff2'), url(${fontSemiBoldWoff}) format('woff');
  }
  @font-face {
    font-family: 'GillSansNova-Medium';
    src: url(${fontMediumWoff2}) format('woff2'), url(${fontMediumWoff}) format('woff');
  }

  * {
    box-sizing: border-box;
    margin: 0;
  }

  html {
    font-family: 'GillSansNova-Medium', sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    /* overflow: hidden; */
  }

  body {
    background-color: #111;
    font-weight: normal;
    font-size: 12px;
    color: white;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (min-width: 768px) {
      font-size: 1.465vw;
    }

    @media (min-width: 1024px) {
      font-size: 15px;
    }

    @media (min-width: 1440px) {
      font-size: 18px;
    }
  }

  h4 {
    margin-bottom: 1em;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    @media (min-width: 768px) {
      margin-right: 10px;
    }
  }

  ul {
    padding: 0;
  }

  p:not(:last-child) {
    margin-bottom: 1em;
  }

  .pos-bottom {
    position: absolute;
    bottom: 0;
    left: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }

  /* Sections */
`

export default GlobalStyles