import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const NavStyles = styled.nav`
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 8888;
    opacity: 0;
    pointer-events: none;
    transition: opacity .6s ease;

    &.is-open {
      opacity: 1;
      pointer-events: all;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
    }
  }

  li {
    margin-right: 2.930vw;
    list-style: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: normal;

    @media (max-width: 768px) {
      padding: 18px 0;
      font-size: 18px;
      text-align: center;
    }

    @media (min-width: 1024px) {
      margin-right: 30px;
    }
  }

  a {
    line-height: 1.5em;

    @media (min-width: 768px) {
      display: block;
    }
  }
`

const Nav = ({ isOpen, toggleMobileNav }) => {
  return (
    <NavStyles className={isOpen ? `is-open` : ``}>
      <ul>
        <li>
          <Link 
            to={`/`} 
            activeStyle={{ borderBottom: `1px solid #FFF` }}
            onClick={toggleMobileNav}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link 
            to={`/our-team`} 
            activeStyle={{ borderBottom: `1px solid #FFF` }} 
            partiallyActive={true}
            onClick={toggleMobileNav}
          >
            Our
             Team
          </Link>
        </li>
        <li>
          <Link 
            to={`/portfolio-of-services`} 
            activeStyle={{ borderBottom: `1px solid #FFF` }}
            onClick={toggleMobileNav}
          >
            Portfolio Of Services
          </Link>
        </li>
        <li>
          <Link 
            to={`/our-clients`} 
            activeStyle={{ borderBottom: `1px solid #FFF` }}
            onClick={toggleMobileNav}
          >
            Our Clients
          </Link>
        </li>
      </ul>
    </NavStyles>
  )
}

export default Nav
